import { userConstants } from '../../constants';

function user(state = {}, action) {
  switch (action.type) {
  case userConstants.UPDATE_PASSWORD_REQUEST:
    return { updating_password: true };
  case userConstants.UPDATE_PASSWORD_SUCCESS:
    return { password_updated: true };
  case userConstants.UPDATE_PASSWORD_FAILURE:
    return { updating_password: false };

  case userConstants.CREATE_STRIPE_ACCOUNT_REQUEST:
    return { redirecting: true };
  case userConstants.CREATE_STRIPE_ACCOUNT_SUCCESS:
    return { redirect: true };
  case userConstants.CREATE_STRIPE_ACCOUNT_FAILURE:
    return { redirecting: false };

  case userConstants.FIRST_LOGIN_AFFILIATE_REQUEST:
    return { confirming: true };
  case userConstants.FIRST_LOGIN_AFFILIATE_SUCCESS:
    return { confirmed: true };
  case userConstants.FIRST_LOGIN_AFFILIATE_FAILURE:
    return { confirmed: false };

  default:
    return state;
  }
}

export default user;
