const affiliateConstants = {
  GET_FAILURE: 'AFFILIATE_GET_FAILURE',
  GET_REQUEST: 'AFFILIATE_GET_REQUEST',
  GET_SUCCESS: 'AFFILIATE_GET_SUCCESS',

  POST_FAILURE: 'AFFILIATE_POST_FAILURE',
  POST_REQUEST: 'AFFILIATE_POST_REQUEST',
  POST_SUCCESS: 'AFFILIATE_POST_SUCCESS',

  GET_STRIPE_ACC_STATUS_REQUEST: 'AFFILIATE_GET_STRIPE_ACC_STATUS_REQUEST',
  GET_STRIPE_ACC_STATUS_SUCCESS: 'AFFILIATE_GET_STRIPE_ACC_STATUS_SUCCESS',
  GET_STRIPE_ACC_STATUS_FAILURE: 'AFFILIATE_GET_STRIPE_ACC_STATUS_FAILURE',
};

export default affiliateConstants;
