import React from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '208px',
  },
}));

function NotFound() {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open aria-labelledby="form-dialog-title">
      <DialogContent>
        <Typography color="primary" variant="h4" align="center">
          <FormattedMessage id="notFound.title" defaultMessage="Pagina no encontrada" />
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" height="70%" mt={6}>
          <Typography variant="subtitle1" align="center">
            <FormattedMessage id="notFound.subTitle" defaultMessage="No pudimos encontrar la pagina solicitada" />
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default NotFound;
