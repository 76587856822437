import React from 'react';
import { FormattedMessage } from 'react-intl';

const validationConstants = {
  EMAIL_REQUIRED: <FormattedMessage id="validation.emailRequired" defaultMessage="Ingrese su dirección de correo electronico" />,
  EMAIL_INVALID: <FormattedMessage id="validation.emailInvalid" defaultMessage="Ingrese una dirección de correo electronico válida" />,

  PASSWORD_REQUIRED: <FormattedMessage id="validation.passwordRequired" defaultMessage="Ingrese su contraseña" />,
  PASSWORD_MATCH_PATTERN: <FormattedMessage id="validation.passwordMatchPattern" defaultMessage="La contraseña debe cumplir con los requisitos" />,

  PASSWORD_REGEX: 'matchRegexp:^(?=.*\\d)(?=.*[^\\w\\s)]|[_])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$',
};

export default validationConstants;
