import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog, Box, Typography, DialogContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import Button from '../controls/Button';
import { affiliatesActions } from '../../redux/actions';
import { affiliatesText } from '../../constants';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '780px',
  },
  dialogPaper: {
    padding: '40px 40px 25px',
  },
  termsDiv: {
    maxWidth: '554px',
    maxHeight: '264px',
    overflow: 'auto',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
}));

function TermsAndConditionsModal({
  open, setOpen, affiliateId, discountCoupon, affiliateCommission,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirmingTyC = useSelector((state) => state.user.confirming);

  const onClickAccept = () => {
    dispatch(affiliatesActions.setFirstLogin(affiliateId));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      maxWidth="720px"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 700 }} align="center">
          {affiliatesText.termsAndConditions}
        </Typography>

        <Box className={classes.termsDiv} mt={3}>
          {affiliatesText.termsAndConditionsTitles.map((text, key) => {
            const keyId = `${key}-ID`;
            return (
              <Typography key={keyId} align="center" style={{ fontSize: '14px', fontWeight: 600 }}>
                {text}
              </Typography>
            );
          })}
          {affiliatesText.termsAndConditionsTexts.map((text, key) => {
            const keyId = `${key}-ID`;
            return (
              <Typography key={keyId}>
                {text}
              </Typography>
            );
          })}
        </Box>
        {affiliateCommission && discountCoupon && (
          <Box mt={3}>
            <Typography variant="subtitle1">
              <b>
                <FormattedMessage id="affiliates.commissionPercentage" defaultMessage="Porcentaje de comisión por cada referido: " />
              </b>
              {affiliateCommission}
              %
            </Typography>
            <Typography variant="subtitle1">
              <b>
                <FormattedMessage id="affiliates.discountPercentage" defaultMessage="Porcentaje de descuento a aplicar en los primeros 3 meses a cada referido: " />
              </b>
              {discountCoupon}
              %
            </Typography>
          </Box>
        )}
        {affiliateId
          ? (
            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
              <Button
                type="submit"
                variant="outlined"
                text={<FormattedMessage id="affiliates.rejectTerms" defaultMessage="Rechazar" />}
                component={Link}
                to="/logout"
                style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
              />
              <Button
                type="submit"
                text={<FormattedMessage id="app.accept" defaultMessage="Aceptar" />}
                onClick={onClickAccept}
                style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
                loading={confirmingTyC}
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
              <Button
                type="submit"
                text={<FormattedMessage id="app.close" defaultMessage="Cerrar" />}
                onClick={() => setOpen(false)}
                style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
              />
            </Box>
          )}

      </DialogContent>
    </Dialog>
  );
}

export default TermsAndConditionsModal;
