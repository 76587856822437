import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { affiliatesActions } from '../../../redux/actions';
import ReferralSystemActivatedModal from '../../Home/ReferralSystemActivatedModal';

const AffiliateReturn = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const affiliate = useSelector((state) => state.affiliate.data);

  useEffect(() => {
    if (user.id) {
      dispatch(affiliatesActions.getStripeAccountStatus(user.id));
    }
  }, [dispatch, user]);

  return (
    <>
      {
        affiliate && affiliate.referalSystemEnabled
        && <ReferralSystemActivatedModal />
      }
    </>
  );
};

export default AffiliateReturn;
