import React from 'react';
import {
  Dialog, Box, Typography, DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import LaunchIcon from '@mui/icons-material/Launch';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../controls/Button';
import { affiliatesText } from '../../constants';
import { affiliatesActions } from '../../redux/actions';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: '468px',
    padding: '24px',
  },
  textDiv: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function CreateAccountModal({ openModal, affiliateId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirecting = useSelector((state) => state.user.redirecting);
  const redirect = useSelector((state) => state.user.redirect);
  const redirectLabel = redirect
    ? <FormattedMessage id="app.redirect" defaultMessage="Redireccionando..." />
    : <FormattedMessage id="app.continue" defaultMessage="Continuar" />;

  const onClickContinue = () => { dispatch(affiliatesActions.createStripeAccount(affiliateId)); };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={openModal}
      onClose={onClickContinue}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 700 }} align="center">
          {affiliatesText.createAccount}
        </Typography>

        <Box className={classes.textDiv} mt={3}>
          <Typography variant="subtitle2">
            {affiliatesText.createAccountText}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <Button
            type="submit"
            startIcon={!redirect && <LaunchIcon size="small" style={{ fontSize: '12px' }} />}
            text={redirectLabel}
            style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
            onClick={onClickContinue}
            loading={redirecting}
          />
        </Box>

      </DialogContent>
    </Dialog>
  );
}

export default CreateAccountModal;
