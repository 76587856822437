import { affiliateConstants } from '../../constants';

function affiliates(state = {}, action) {
  switch (action.type) {
  case affiliateConstants.GET_REQUEST:
  case affiliateConstants.GET_STRIPE_ACC_STATUS_REQUEST:
    return { getting: true };
  case affiliateConstants.GET_SUCCESS:
  case affiliateConstants.GET_STRIPE_ACC_STATUS_SUCCESS:
    return { data: action.data };
  case affiliateConstants.GET_FAILURE:
  case affiliateConstants.GET_STRIPE_ACC_STATUS_FAILURE:
    return { };

  case affiliateConstants.POST_REQUEST:
    return { requesting: true };
  case affiliateConstants.POST_SUCCESS:
    return { data: action.data };
  case affiliateConstants.POST_FAILURE:
    return { };
  default:
    return state;
  }
}

export default affiliates;
