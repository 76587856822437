import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import App from './containers/App';
import { store } from './helpers';
import es from './languages/es-AR.json';
import en from './languages/en-US.json';
import it from './languages/it-IT.json';

const local = navigator.language;

function getLocalLanguage() {
  if (local === 'en-US') return en;
  if (local === 'it-IT') return it;
  return es;
}

const language = getLocalLanguage();
ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={local} messages={language}>
      <Provider store={store}>
        <App />
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
