import React from 'react';
import {
  Router, Switch, Route,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import * as Pages from '../components';
import NotFound from '../components/ErrorPages/NotFound/NotFound';
import SnackbarAlert from '../components/controls/SnackbarAlert';
import theme from '../assets/themeConfig';
import { PrivateRouteWithParams, LoggedInRoute } from '../components';
import { history } from '../helpers';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert />
      <Router history={history}>
        <Switch>
          <LoggedInRoute path="/" exact component={Pages.AuthPage} />
          <LoggedInRoute path="/login" exact component={Pages.AuthPage} />
          <Route path="/logout" exact component={Pages.AuthPage} />
          <Route path="/auth/confirmation" exact component={Pages.EmailConfirmationPage} />
          <Route path="/auth/password/forgot" exact component={Pages.ForgotPasswordPage} />
          <Route path="/auth/password/reset" exact component={Pages.ResetPasswordPage} />
          <PrivateRouteWithParams path="/stripe/refresh" exact component={Pages.AffiliateRefresh} />
          <PrivateRouteWithParams path="/stripe/return" exact component={Pages.AffiliateReturn} />
          <PrivateRouteWithParams path="/home" exact component={Pages.HomePage} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
