import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function LoggedInRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        !localStorage.getItem('user')
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
      )}
    />
  );
}

export default LoggedInRoute;
