import React from 'react';
import {
  Box, Typography, Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import affiliatesLogo from '../../../assets/pertix-affiliates-logo.svg';
import backgroundImage from '../../../assets/pertix-affiliates-image.png';
import colors from '../../../assets/colors';
import { affiliateHelpers } from '../../../helpers';
import { affiliatesText } from '../../../constants';
import { alertActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    boxSizing: 'border-box',
    padding: '35px 40px 40px 70px',
    [theme.breakpoints.down('lg')]: {
      padding: '35px 40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  },
  mask: {
    background: 'linear-gradient(90deg, #FFFFFF -8.06%, rgba(255, 255, 255, 0) 69.17%), rgba(254, 254, 254, 0.35)',
    height: '100%',
    width: '100%',
  },
  imageDiv: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  urlButtonWrapper: {
    background: '#F1F1F1',
    borderRadius: '38px',
    display: 'flex',
    flexDirection: 'row',
    height: '55px',
    justifyContent: 'space-between',
    width: '100%',
  },
  urlButtonCopyContainer: {
    alignItems: 'center',
    background: colors.orangeLight,
    borderRadius: '33px',
    color: '#FFF',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'center',
    width: '143px',
  },
  urlButtonTextContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  activatedContainer: {
    alignItems: 'center',
    borderRadius: '30px',
    color: '#FFF',
    display: 'flex',
    height: '45px',
    justifyContent: 'left',
    maxWidth: '138px',
    padding: '0px 12px',
  },
}));

function AffiliatePage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state.affiliate.data);

  const copyURLtoClipboard = () => {
    navigator.clipboard.writeText(affiliateHelpers.getAffiliateURL(affiliate.couponId));
    dispatch(alertActions.success(affiliatesText.copiedToClipboard));
  };

  return (
    // eslint-disable-next-line no-dupe-keys
    <Box style={{ height: '100%', height: 'calc(100% - 81px)' }}>
      <Grid container columns={20} style={{ height: '100%' }}>
        <Grid item xs={20} md={12} className={classes.mainDiv}>
          <Box mb={5.5}>
            <img src={affiliatesLogo} alt="Pertix Logo" />
          </Box>

          <Box mr={3}>
            <Typography mb={1.5} variant="h5" style={{ color: colors.orangeLight }}>
              {affiliatesText.welcome}
            </Typography>
            <Typography variant="h6">
              <FormattedMessage
                id="affiliates.shareYourURL"
                defaultMessage="Compartí tu URL con tus referidos para que puedan acceder a un descuento en el plan Starter durante los primeros 3 meses."
                values={{ percentOff: affiliate.discountCoupon }}
              />
            </Typography>
          </Box>

          <Box mt={6}>
            <Typography mb={1.5} variant="h4">
              {affiliatesText.affiliateURL}
            </Typography>
          </Box>

          <Box className={classes.urlButtonWrapper}>
            <Box className={classes.urlButtonTextContainer}>
              <Typography ml={3} variant="h5" style={{ fontWeight: 400, fontSize: '17px' }}>
                {affiliateHelpers.getAffiliateURL(affiliate.couponId)}
              </Typography>
            </Box>
            <Box className={classes.urlButtonCopyContainer} onClick={copyURLtoClipboard}>
              <ContentCopyIcon size="small" />
              <Typography ml={1.5} variant="h6" style={{ fontWeight: 700 }}>
                {affiliatesText.copy}
              </Typography>
            </Box>
          </Box>

          <Box mt={6}>
            <Typography mb={1.5} variant="h4">
              {affiliatesText.referralSystem}
            </Typography>
            <Box
              className={classes.activatedContainer}
              style={{
                background: affiliate && affiliate.referalSystemEnabled
                  ? colors.green
                  : colors.orange,
              }}
            >
              {affiliate && affiliate.referalSystemEnabled
                ? <CheckCircleIcon />
                : <ErrorIcon />}
              <Typography variant="h6" style={{ fontWeight: 600, marginLeft: '12px' }}>
                {affiliate && affiliate.referalSystemEnabled
                  ? affiliatesText.activated
                  : affiliatesText.notActivated}
              </Typography>
            </Box>

          </Box>
        </Grid>

        <Grid item xs={20} md={8} className={classes.imageDiv}>
          <Box className={classes.mask} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AffiliatePage;
