import React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import userActions from '../../redux/actions/user.actions';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    maxWidth: '361px',
    border: '0.5px solid #C4C4C4',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
  },
  div: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#7A7A7A',
  },
}));

function GoogleButton({ theme }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleLoginSuccess(response) {
    if (response.tokenId) {
      dispatch(userActions.googleLogin(response.tokenId));
    }
  }

  return (
    <div className={classes.button}>
      <GoogleLogin
        clientId={CLIENT_ID}
        onSuccess={handleLoginSuccess}
        cookiePolicy="single_host_origin"
        theme={theme}
        className={classes.div}
      >
        <div>
          <span className={classes.text}>
            {' '}
            <FormattedMessage id="app.googleLogin" defaultMessage="Log in with Google" />
            {' '}

          </span>
        </div>

      </GoogleLogin>
    </div>
  );
}

export default GoogleButton;
