import { userConstants } from '../../constants';

export default function registration(state = {}, action) {
  switch (action.type) {
  case userConstants.REGISTER_CONFIRM_REQUEST:
    return { confirming: true };
  case userConstants.REGISTER_CONFIRM_SUCCESS:
    return { confirmed: true };
  case userConstants.REGISTER_CONFIRM_FAILURE:
    return { confirmed: false };
  case userConstants.REGISTER_CONFIRM_CLEAR:
    return {};
  default:
    return state;
  }
}
