import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, Grid, InputAdornment, IconButton, Typography,
} from '@mui/material';
import queryString from 'query-string';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthLayout from '../AuthLayout/AuthLayout';
import Button from '../../controls/Button';
import { userActions } from '../../../redux/actions';
import { validationConstants } from '../../../constants';

const initialFieldValues = {
  newPassword: '',
  newPasswordConfirmation: '',
  showPassword: false,
};

function ResetPasswordPage({ location }) {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const parsedQueryString = queryString.parse(location.search);
  const [queryParams] = useState({
    email: parsedQueryString.email,
    token: parsedQueryString.token,
  });
  const [values, setValues] = useState(initialFieldValues);
  const { email, token } = queryParams;
  const { newPassword, showPassword } = values;
  const intl = useIntl();
  const passwordCriteriaText = intl.formatMessage({ id: 'app.passwordCriteria', defaultMessage: 'La contraseña debe tener al menos 6 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico' });

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'reset.docTitle', defaultMessage: 'Pertix - Cambio de contraseña' });
  }, [intl]);

  function handleSubmit(e) {
    e.preventDefault();
    if (email && token) {
      setSubmitted(true);
      dispatch(userActions.resetPassword(email, newPassword, token));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <AuthLayout
      pageTitle={(
        <FormattedMessage
          id="reset.title"
          defaultMessage="Cambiar contraseña"
        />
      )}
      pageSubtitle={(
        <FormattedMessage
          id="reset.subTitle"
          defaultMessage="Escriba su nueva contraseña"
        />
      )}
    >
      <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit} instantValidate>
        <Grid container>
          <Grid item xs={12}>
            <TextValidator
              value={newPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              name="newPassword"
              label={<FormattedMessage id="app.password" defaultMessage="Contraseña" />}
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              autoComplete="current-password"
              validators={['required', validationConstants.PASSWORD_REGEX]}
              errorMessages={
                [validationConstants.PASSWORD_REQUIRED, validationConstants.PASSWORD_MATCH_PATTERN]
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      tabIndex={-1}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography color="textSecondary" variant="caption">{passwordCriteriaText}</Typography>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Button
            type="submit"
            loading={submitted}
            text={<FormattedMessage id="app.next" defaultMessage="Siguiente" />}
          />
        </Box>
      </ValidatorForm>
    </AuthLayout>
  );
}

export default ResetPasswordPage;
