import React from 'react';
import {
  Paper, Box, Grid, Typography, Hidden,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import pertixLogo from '../../../assets/pertix-affiliates-logo.svg';
import pertixBackgroundImage from '../../../assets/fondo.png';
import { alertActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  mask: {
    background: 'linear-gradient(180deg, #FFFFFF -8.06%, rgba(255, 255, 255, 0) 69.17%), rgba(254, 254, 254, 0.35)',
    height: '100vh',
    width: '100%',
  },
  image: {
    backgroundImage: `url(${pertixBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundPosition: 'center right',
    backgroundSize: 'cover',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  text: {
    position: 'relative',
    transform: 'none',
  },
  paper: {
    margin: theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    position: 'relative',
    width: '258.05px',
    height: '92.3px',
  },
  containerPaper: {
    height: '100vh',
    overflow: 'auto',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
}));

const AuthLayout = ({ children, pageTitle, pageSubtitle }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClose() {
    dispatch(alertActions.clear());
  }

  return (
    <Grid container component="main" className={classes.root} onClick={handleClose}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        className={classes.containerPaper}
      >
        <div className={classes.paper}>
          <img alt="" src={pertixLogo} className={classes.logo} />
          <Box display="flex" alignItems="center" justifyContent="center" my={3} px={10}>
            <Typography align="center" variant="subtitle1" style={{ fontSize: '14px' }}>
              <strong>{pageTitle}</strong>
            </Typography>
          </Box>

          { pageSubtitle && (
            <Box px={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {pageSubtitle}
              </Typography>
            </Box>
          )}

          {children}
        </div>
      </Grid>
      <Grid item xs={false} sm={false} md={6} lg={7} className={classes.image} style={{ height: '100vh', overflowY: 'auto' }}>
        <Box className={classes.mask}>
          <Hidden smDown>
            <Box px={6} pt={10} className={classes.text}>
              <Typography variant="h1" display="block" gutterBottom>
                <Grid item xs={12}>
                  <FormattedMessage id="Sistema de " defaultMessage="Sistema de " />
                </Grid>
                <Grid item xs={12}>
                  <strong>
                    {' '}
                    <FormattedMessage id="gestion integral " defaultMessage="gestion integral " />
                    {' '}
                  </strong>
                </Grid>
                <Grid item xs={12}>
                  <FormattedMessage id="de organizaciones" defaultMessage="de organizaciones" />
                </Grid>
              </Typography>
            </Box>
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
