/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const errorCode = {
  600: <FormattedMessage id="error.differentFromZero" defaultMessage="Entity id is different from zero." />,
  601: <FormattedMessage id="error.differentIdFromQuery" defaultMessage="Entity id is not the same as query string id." />,
  602: <FormattedMessage id="error.idRequired" defaultMessage="The id is required." />,
  603: <FormattedMessage id="error.mailAlreadyRegistered" defaultMessage="The email is already registered." />,
  604: <FormattedMessage id="error.invalidMail" defaultMessage="The email is invalid." />,
  605: <FormattedMessage id="error.invalidToken" defaultMessage="The token is invalid." />,
  606: <FormattedMessage id="error.notConfirmedMail" defaultMessage="The email is not confirmed." />,
  607: <FormattedMessage id="error.incorrectMailOrPassword" defaultMessage="The email or password are incorrect." />,
  608: <FormattedMessage id="error.userBlocked" defaultMessage="The user is locked out." />,
  609: <FormattedMessage id="error.incorrectMailOrToken" defaultMessage="The email or token are invalid." />,
  610: <FormattedMessage id="error.roleNameRequired" defaultMessage="The role is required." />,
  611: <FormattedMessage id="error.roleCouldNotBeAssigned" defaultMessage="The role could not be assigned." />,
  612: <FormattedMessage id="error.unexpectedErrorGoogleLogin" defaultMessage="Unexpected Error in Google login." />,
  613: <FormattedMessage id="error.userNotfound" defaultMessage="User was not found." />,
  614: <FormattedMessage id="error.userAlreadyAssignedToCompany" defaultMessage="Company already has a name." />,
  615: <FormattedMessage id="error.entityNotFound" defaultMessage="The entity was not found, was delete or does not belong to your company." />,
  616: <FormattedMessage id="error.userWasNotCreated" defaultMessage="User can not be created." />,
  617: <FormattedMessage id="error.roleWasNotCreated" defaultMessage="Role can not be created." />,
  618: <FormattedMessage id="error.userCouldNotBeNotUpdated" defaultMessage="User could not be updated." />,
  619: <FormattedMessage id="error.ErrorWhenSavingFile" defaultMessage="It had been an error when trying to copy the file into the hard drive." />,
  620: <FormattedMessage id="error.FileWithoutFileName" defaultMessage="The file does not have a file name." />,
  621: <FormattedMessage id="error.DocumentHasNotAFile" defaultMessage="The document does not have a file." />,
  622: <FormattedMessage id="error.StripeError" defaultMessage="It had been an error when calling Stripe." />,
  623: <FormattedMessage id="error.UserWithNoCompany" defaultMessage="The user is not assigned to a Company." />,
  624: <FormattedMessage id="error.CompanyHasNoStripeCustomerId" defaultMessage="The company has not stripe customerId." />,
  625: <FormattedMessage id="error.WebhookCannotManageStripeEvent" defaultMessage="The stripe event cannot be managed." />,
  626: <FormattedMessage id="error.ErrorGettingWebhookEvent" defaultMessage="There had been an error when getting the webhook event." />,
  627: <FormattedMessage id="error.NoUnpaidPeriod" defaultMessage="There are no unpaid periods to pay." />,
  628: <FormattedMessage id="error.NonExistentCustomerId" defaultMessage="The requested customerId does not exist." />,
  629: <FormattedMessage id="error.DocumentTypeDoesNotMatchUrl" defaultMessage="The Document Type differs the type manage by this URL." />,
  630: <FormattedMessage id="error.UserCanNotAssignRole" defaultMessage="The logged user has no permision to assign one of the roles." />,
  631: <FormattedMessage id="error.passwordCouldNotBeUpdated" defaultMessage="The password change had failed." />,
  632: <FormattedMessage id="error.ImageNotSent" defaultMessage="The image was not sent." />,
  633: <FormattedMessage id="error.RepeatedOrderSwotItem" defaultMessage="There is a SWOT Type which has two items with the same order." />,
  634: <FormattedMessage id="error.PasswordIsRequired" defaultMessage="Password is required." />,
  635: <FormattedMessage id="error.UserCanNotPerformTheAction" defaultMessage="The user can not perform the action due to its roles assigned." />,
  636: <FormattedMessage id="error.ExcelCanNotBeImported" defaultMessage="The Excel can not be imported." />,
  637: <FormattedMessage id="error.EmailIsRequired" defaultMessage="The email is required." />,
  638: <FormattedMessage id="error.EmailIsInvalid" defaultMessage="The email is invalid." />,
  639: <FormattedMessage id="error.RoleNotFound" defaultMessage="The Role does not exist." />,
  640: <FormattedMessage id="error.DateIsInInvalidFormat" defaultMessage="The Date is in invalid format." />,
  641: <FormattedMessage id="error.OutlineDoesNotExist" defaultMessage="The Outline does not exist." />,
  642: <FormattedMessage id="error.FileIsNotASupportedExcel" defaultMessage="The file is not a supported excel with a XLSX extension." />,
  643: <FormattedMessage id="error.RepeatedEmail" defaultMessage="The email is already in the excel file." />,
  644: <FormattedMessage id="error.NamesAreRequired" defaultMessage="The names are required." />,
  645: <FormattedMessage id="error.SurnamesAreRequired" defaultMessage="The surnames are required." />,
  646: <FormattedMessage id="error.TitleIsRequired" defaultMessage="The title is required." />,
  647: <FormattedMessage id="error.YearCannotBeParsed" defaultMessage="The Year is in invalid format." />,
  648: <FormattedMessage id="error.FrequencyDoNotBelongToOptions" defaultMessage="The Frequency do not belong to the options." />,
  649: <FormattedMessage id="error.OperatorDoNotBelongToOptions" defaultMessage="The Operator do not belong to the options." />,
  650: <FormattedMessage id="error.OriginIsRequired" defaultMessage="The origin is required." />,
  651: <FormattedMessage id="error.DetectionDateIsRequired" defaultMessage="The detection date is required." />,
  652: <FormattedMessage id="error.CurrentStateIsRequired" defaultMessage="The Current State is required." />,
  653: <FormattedMessage id="error.OriginDoNotBelongToOptions" defaultMessage="The Origin do not belong to the options." />,
  654: <FormattedMessage id="error.CurrentStateDoNotBelongToOptions" defaultMessage="The Current State do not belong to the options." />,
  655: <FormattedMessage id="error.EffectivenessVerifiedDoNotBelongToOptions" defaultMessage="The Effectiveness Verified do not belong to the options." />,
  656: <FormattedMessage id="error.DescriptionIsRequired" defaultMessage="The Description is required." />,
  657: <FormattedMessage id="error.NextEvaluationDateIsRequired" defaultMessage="The Next Evaluation Date is required." />,
  658: <FormattedMessage id="error.ContextIsRequired" defaultMessage="The Context is required." />,
  659: <FormattedMessage id="error.ContextDoNotBelongToOptions" defaultMessage="The Context do not belong to the options." />,
  660: <FormattedMessage id="error.TypeIsRequired" defaultMessage="The Type is required." />,
  661: <FormattedMessage id="error.TypeDoNotBelongToOptions" defaultMessage="The Type do not belong to the options." />,
  662: <FormattedMessage id="error.ActionsAreRequired" defaultMessage="The Actions are required." />,
  663: <FormattedMessage id="error.ProbabilityIsRequired" defaultMessage="The Probability is required." />,
  664: <FormattedMessage id="error.ImpactIsRequired" defaultMessage="The Impact is required." />,
  665: <FormattedMessage id="error.ProbabilityDoNotBelongToOptions" defaultMessage="The Probability do not belong to the options." />,
  666: <FormattedMessage id="error.ImpactDoNotBelongToOptions" defaultMessage="The Impact do not belong to the options." />,
  667: <FormattedMessage id="error.ProbabilityCannotBeParsed" defaultMessage="The Probability is in invalid format." />,
  668: <FormattedMessage id="error.ImpactCannotBeParsed" defaultMessage="The Impact is in invalid format." />,
  669: <FormattedMessage id="error.EvaluationDateIsRequired" defaultMessage="The Evaluation Date is required." />,
  670: <FormattedMessage id="error.ErrorOnDownloadFileFromBlob" defaultMessage="There was an error when downloading the file from the blob." />,
  671: <FormattedMessage id="error.PersonHasNoPhoto" defaultMessage="There was no photo for the given person." />,
  672: <FormattedMessage id="error.CompanyCreatorUserCannotBeDeleted" defaultMessage="The creator of the company can not be deleted." />,
  673: <FormattedMessage id="error.UserLimitExceeded" defaultMessage="The user limit has been exceeded." />,
  674: <FormattedMessage id="error.StorageLimitExceeded" defaultMessage="The storage limit has been exceeded." />,
  675: <FormattedMessage id="error.CompanyHasNoActiveSubscription" defaultMessage="The Company has no active subscription." />,
  default: <FormattedMessage id="error.serverError" defaultMessage="Oops! An unexpected error occurred" />,
};

const errorTexts = (code) => errorCode[code] || <FormattedMessage id="error.serverError" defaultMessage="Oops! An unexpected error occurred" />;

const errorCodeHelper = {
  errorTexts,
};

export default errorCodeHelper;
