import React from 'react';
import { FormattedMessage } from 'react-intl';

const affiliatesText = {
  activated: <FormattedMessage id="affiliates.activated" defaultMessage="Activado" />,
  affiliateURL: <FormattedMessage id="affiliates.affiliateURL" defaultMessage="URL para referidos" />,
  copiedToClipboard: <FormattedMessage id="affiliates.copiedToClipboard" defaultMessage="URL copiada al portapapeles" />,
  copy: <FormattedMessage id="affiliates.copy" defaultMessage="Copiar" />,
  createAccount: <FormattedMessage id="affiliates.createAccount" defaultMessage="Crear cuenta" />,
  createAccountText: <FormattedMessage
    id="affiliates.createAccountText"
    defaultMessage="Serás redirigido a otra página para finalizar tu proceso de creación de cuenta.
  Regresa a Pertix una vez hayas completado todos los campos necesarios."
  />,
  notActivated: <FormattedMessage id="affiliates.notActivated" defaultMessage="No Activado" />,
  referralSystem: <FormattedMessage id="affiliates.referralSystem" defaultMessage="Sistema de referidos" />,
  referralSystemActivated: <FormattedMessage
    id="affiliates.referralSystemActivated"
    defaultMessage="SISTEMA DE REFERIDOS ACTIVADO"
  />,
  referralSystemActivatedText: <FormattedMessage
    id="affiliates.referralSystemActivatedText"
    defaultMessage="El sistema de referidos fue activado con éxito en su cuenta.
  Comparta su link de afiliado para comenzar a referir empresas."
  />,
  welcome: <FormattedMessage id="affiliates.welcome" defaultMessage="¡Bienvenido a Pertix Affiliates!" />,
  termsAndConditions: <FormattedMessage id="affiliates.termsAndConditions" defaultMessage="Términos y condiciones" />,
  termsAndConditionsTitles: [
    <FormattedMessage id="termsAndConditions.title" defaultMessage="Acuerdo de operación comercial conjunta entre" />,
    <FormattedMessage id="termsAndConditions.title2" defaultMessage="Pertix Tech LLC y sus Aliados Comerciales" />,
    <br />,
  ],
  termsAndConditionsTexts: [
    <FormattedMessage
      id="termsAndConditions.firstPointTitle"
      defaultMessage="1. En el presente acuerdo de servicios recíprocos, Pertix Tech LLC (en adelante, “Pertix”) establece las condiciones comerciales por las cuales las organizaciones, empresas o individuos que cooperen en la generación de suscripciones a la plataforma {link} (en adelante, el “Aliados”), realizarán una operación comercial de beneficio recíproco."
      values={{ link: <a href="https://www.pertixtech.com" aria-labelledby="https://www.pertixtech.com" target="_blank" rel="noreferrer"><FormattedMessage id="link" defaultMessage="www.pertixtech.com" /></a> }}
    />,
    <br />,
    <FormattedMessage id="termsAndConditions.secondPointTitle" defaultMessage="2. Condiciones generales:" />,
    <FormattedMessage id="termsAndConditions.secondPointFirstSubpoint" defaultMessage="i. La potestad de administración de los datos que los suscriptores carguen en Pertix, como parte de su proceso de registro, abono de suscripción, actividad en la plataforma o baja, eses exclusiva potestad y obligación de Pertix." />,
    <FormattedMessage id="termsAndConditions.secondPointSecondSubpoint" defaultMessage="ii. Todos los suscriptores, como parte de la promoción operada por el Aliado, serán acreedores a un cupón de descuento por tres meses del plan “Starter” de Pertix, o de un descuento del un porcentaje previamente preestablecido en el pago de membresía anual, a elección del suscriptor." />,
    <FormattedMessage id="termsAndConditions.secondPointThirdSubpoint" defaultMessage="iii. En caso de que el suscriptor al registrarse en Pertix no haga uso del cupón promocional, optando por la membresía en plan “Free”, este acto no producirá efecto económico alguno para el Aliado o para Pertix, independientemente del origen del prospecto." />,
    <FormattedMessage id="termsAndConditions.secondPointFourthSubpoint" defaultMessage="iv. El Aliado recibirá una comisión comercial porcentual preestablecida del pago total realizado por el suscriptor, cuando el suscriptor utilice el cupón promocional correspondiente, en forma automática y en el momento que éste se produzca, sin intermediar trámite administrativo ni facturación previa. Dicho pago se produce dentro de la plataforma Stripe, sin intervención directa de Pertix o del Aliado." />,
    <FormattedMessage id="termsAndConditions.secondPointFifthSubpoint" defaultMessage="v. El Aliado recibirá el porcentaje acordado en el inciso anterior, cada vez que el suscriptor al que haya acercado a Pertix realice un pago en la plataforma, en forma permanente, durante todo el tiempo que dure la relación entre el suscriptor y Pertix." />,
    <FormattedMessage id="termsAndConditions.secondPointSixthSubpoint" defaultMessage="vi. Los ingresos monetarios del Aliado se incrementarán cada vez que el suscriptor al que haya acercado a Pertix incremente el plan de membresía o solicite más prestaciones a Pertix, dado que la totalidad de los pagos por las prestaciones de Pertix se realizarán vía Stripe." />,
    <FormattedMessage id="termsAndConditions.secondPointSeventhSubpoint" defaultMessage="vii. En el caso de que el suscriptor elija utilizar el cupón promocional para acceder a los tres meses de uso gratuito del plan “Starter”, el Aliado se hará acreedor del porcentaje correspondiente de la membresía con el primer pago efectivamente ingresado a Pertix por parte del suscriptor. Por tanto, en el caso descripto, la primera comisión devengará al final del período de promoción, cuando caduque el beneficio obtenido por el suscriptor." />,
    <FormattedMessage id="termsAndConditions.secondPointEighthSubpoint" defaultMessage="viii. Si en el momento de realizar el primer pago luego del período de prueba promocional el suscriptor decide no continuar con su membresía en Pertix, el Aliado no podrá reclamar el devengamiento de comisión alguna." />,
    <FormattedMessage id="termsAndConditions.secondPointNinthSubpoint" defaultMessage="ix. La plataforma de información comercial que Pertix pone a disposición del Aliado informará a éste de los montos producidos por comisiones en tiempo real, con montos totales y parciales (en períodos mensuales o bimestrales), así como de las novedades producidas por los suscriptores a los que haya acercado a Pertix, tales como altas de usuarios con o sin uso del cupón promocional, cambios de plan, compras adicionales, bajas de plan, bajas definitivas, etc." />,
    <br />,
    <FormattedMessage id="termsAndConditions.thirdPointTitle" defaultMessage="3. Pertix será responsable de:" />,
    <FormattedMessage id="termsAndConditions.thirdPointFirstSubpoint" defaultMessage="i. Administrar los datos de los suscriptores de la plataforma y del Aliado, asegurando su confidencialidad." />,
    <FormattedMessage id="termsAndConditions.thirdPointSecondSubpoint" defaultMessage="ii. Administrar los mecanismos que permitan que el Aliados generen los ingresos monetarios comprometidos en el inciso iv del art. 2, y para que el Aliado pueda controlar el beneficio obtenido por sus gestiones comerciales y/o promocionales." />,
    <FormattedMessage id="termsAndConditions.thirdPointThirdSubpoint" defaultMessage="iii. Disponibilizar las herramientas de registro de Aliados, emisión de cupones promocionales para suscriptores, y reportes de actividad del Aliado (mediante automatización de reporting, vía tablero BI en línea)." />,
    <FormattedMessage id="termsAndConditions.thirdPointFourthSubpoint" defaultMessage="iv. Asegurar el pago de las comisiones comerciales vía Stripe, amén de que éste sea un proceso automático." />,
    <FormattedMessage id="termsAndConditions.thirdPointFifthSubpoint" defaultMessage="v. Poner a disposición del Aliado la cantidad de piezas promocionales que la Dirección de Pertix considere conveniente, como apoyo de las campañas de difusión del Aliado." />,
    <FormattedMessage id="termsAndConditions.thirdPointSixthSubpoint" defaultMessage="vi. Informar de todas las novedades respecto del negocio conjunto al Aliado, en forma inmediata en el momento que se produzcan, por los canales definidos a tales efectos por Pertix." />,
    <FormattedMessage id="termsAndConditions.thirdPointSeventhSubpoint" defaultMessage="vii. Pertix deberá informar al Aliado de las decisiones sobre promociones futuras destinadas a estimular la compra de servicios por parte de los suscriptores, sin esperar por ello objeción alguna por parte del Aliado." />,
    <br />,
    <FormattedMessage id="termsAndConditions.fourthPointTitle" defaultMessage="4. El Aliado será responsable de:" />,
    <FormattedMessage id="termsAndConditions.fourthPointFirstSubpoint" defaultMessage="i. Arbitrar los medios necesarios para lograr suscripciones a la plataforma Pertix, las cuales se promocionarán mediante un código de descuento que la plataforma asignará a cada suscriptor;" />,
    <FormattedMessage id="termsAndConditions.fourthPointSecondSubpoint" defaultMessage="ii. Iniciar su registro en la plataforma a fin de generar la URL para que los destinatarios de sus acciones puedan generar su código de descuento;" />,
    <FormattedMessage id="termsAndConditions.fourthPointThirdSubpoint" defaultMessage="iii. Generar su propia cuenta de Stripe (www.stripe.com) a fin de recibir el producido de sus campañas de suscripción;" />,
    <FormattedMessage id="termsAndConditions.fourthPointFourthSubpoint" defaultMessage="iv. Facturar las comisiones generadas en forma mensual sobre el producido por comisiones sobre suscripciones, y oportunamente acreditados en su cuenta de Stripe, a la entidad que Pertix determine. Dicha facturación puede ser realizada bajo la entidad física o societaria que el Aliado posea, siempre que ésta se encuentre dentro de los límites de la legalidad del territorio donde el Aliado tenga domicilio legal o comercial." />,
    <br />,
    <FormattedMessage id="termsAndConditions.fifthPointTitle" defaultMessage="5. Acciones conjuntas." />,
    <FormattedMessage id="termsAndConditions.fifthPointFirstSubpoint" defaultMessage="i. En caso de que el Aliado requiera asistencia en la difusión de la plataforma y el servicio, Pertix podrá (aunque no deberá) analizar alternativas de colaboración con el Aliado." />,
    <FormattedMessage id="termsAndConditions.fifthPointSecondSubpoint" defaultMessage="ii. Las acciones conjuntas que Pertix podrá analizar para la realización conjunta con el Aliado incluyen, por ejemplo, la provisión de capacitadores para la realización de webinars organizados por el Aliado, o la creación de materiales de difusión particulares, cuya realización será objeto de decisiones ad-hoc por parte de Pertix." />,
    <FormattedMessage id="termsAndConditions.fifthPointThirdSubpoint" defaultMessage="iii. Las acciones conjuntas que incluyan recursos de Pertix involucrados en la captación de suscriptores potenciales atraídos por el Aliado no modificarán, en ningún caso, las condiciones comerciales sobre comisiones estipuladas en el artículo 2 del presente acuerdo." />,
    <br />,
    <FormattedMessage id="termsAndConditions.sixthPointTitle" defaultMessage="6. Limitaciones al programa promocional." />,
    <FormattedMessage id="termsAndConditions.sixthPointFirstSubpoint" defaultMessage="i. Los suscriptores que hayan tenido un plan pago con anterioridad al acuerdo entre Pertix y el Aliado no podrán recibir los beneficios del cupón promocional, objeto de este acuerdo." />,
    <FormattedMessage id="termsAndConditions.sixthPointSecondSubpoint" defaultMessage="ii. La falta de la facturación correspondiente de las comisiones generadas por los pagos realizados por el o los suscriptores a los que el Aliado haya acercado a Pertix, a mes vencido, puede ser motivo de rescisión unilateral del presente acuerdo." />,
    <br />,
    <FormattedMessage id="termsAndConditions.seventhPointTitle" defaultMessage="7. Rescisión del acuerdo entre Pertix y el Aliado." />,
    <FormattedMessage id="termsAndConditions.seventhPointFirstSubpoint" defaultMessage="i. Pertix se reserva la potestad de rescindir el presente acuerdo, sin la obligación de informar formalmente dicha decisión al Aliado. En tal caso no se generarán más cupones promocionales en la URL correspondiente al Aliado." />,
    <FormattedMessage id="termsAndConditions.seventhPointSecondSubpoint" defaultMessage="ii. En caso de finalización del acuerdo entre Pertix y el Aliado, el Aliado mantendrá el derecho de recibir el producido por comisiones generadas por pagos a Pertix, realizados por el o los suscriptores a los que el Aliado haya acercado a Pertix, mientras dicho suscriptor mantenga su relación con Pertix." />,
    <FormattedMessage id="termsAndConditions.seventhPointThirdSubpoint" defaultMessage="iii. Una vez que el acuerdo haya sido rescindido, Pertix deberá continuar informando al Aliado de los montos de comisiones producidos por los pagos realizados por el o los suscriptores a los que el Aliado haya acercado a Pertix, mediante las herramientas mencionadas en el artículo iii." />,
    <br />,
    <FormattedMessage id="termsAndConditions.eighthPointTitle" defaultMessage="8. Pertix y el Aliado se comprometen a resolver de manera amistosa cualquier desacuerdo, dudas y controversias que pueda surgir en el desarrollo del presente acuerdo, y concuerdan en que todas las dudas y controversias que pudiesen surgir en la ejecución e interpretación del presente acuerdo, serán resueltas amistosamente y de común acuerdo." />,
    <br />,
    <FormattedMessage id="termsAndConditions.ninthPointTitle" defaultMessage="9. Se elige como domicilio especial de ratificación del presente acuerdo a la ciudad de Miami, Estado de Florida, Estados Unidos de América, a cuyos tribunales competentes las partes acuerdan someterse." />,
  ],
};

export default affiliatesText;
