import { createTheme } from '@mui/material/styles';
import colors from './colors';

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    color: colors.blackGrey,
    fontWeight: 400,
    h1: {
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: '36px',
      fontFamily: 'Montserrat',
      color: colors.orangeLight,
    },
    h2: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '38px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '26px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
    },
    h5: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27.5px',
      fontFamily: 'Montserrat',
    },
    h6: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24,2px',
      fontFamily: 'Montserrat',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      fontFamily: 'Montserrat',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
    },
    caption: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '18px',
      fontFamily: 'Montserrat',
      letterSpacing: '0.16px',
    },
    body1: {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  MuiButton: {
    root: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderRadius: 0,
      color: colors.orangeLight,
      transition: 'none',
      fontSize: '0.75rem',
    },
  },
  palette: {
    primary: {
      main: '#F16C24',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D12828',
      contrastText: '#fff',
    },
  },
  overrides: {
    MUIDataTable: {
      paper: {
        backgroundColor: '#F8F8F8',
        boxShadow: 'none',
      },
      responsiveBase: {
        padding: '0 24px 0 24px',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontWeight: 400,
        fontSize: '12px',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: '#F8F8F8 !important',
      },
      data: {
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        color: '#F79A56',
        fontWeight: 'bold',
      },

    },
    MuiTablePagination: {
      spacer: {
        MuiTypography: {
          fontSize: '13px',
          fontWeight: 600,
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '210px',
        fontSize: '12px',
      },
    },

    MuiTableRow: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '16px',
      },
    },

  },
});

export default theme;
