const userConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  GOOGLE_LOGIN_REQUEST: 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILURE: 'GOOGLE_LOGIN_FAILURE',

  REGISTER_CONFIRM_REQUEST: 'USER_REGISTER_CONFIRM_REQUEST',
  REGISTER_CONFIRM_SUCCESS: 'USER_REGISTER_CONFIRM_SUCCESS',
  REGISTER_CONFIRM_FAILURE: 'USER_REGISTER_CONFIRM_FAILURE',
  REGISTER_CONFIRM_CLEAR: 'USER_REGISTER_CONFIRM_CLEAR',

  FORGOT_REQUEST: 'USER_FORGOT_REQUEST',
  FORGOT_SUCCESS: 'USER_FORGOT_SUCCESS',
  FORGOT_FAILURE: 'USER_FORGOT_FAILURE',

  RESET_REQUEST: 'USER_RESET_REQUEST',
  RESET_SUCCESS: 'USER_RESET_SUCCESS',
  RESET_FAILURE: 'USER_RESET_FAILURE',

  LOGOUT: 'USER_LOGOUT',

  UPDATE_PASSWORD_REQUEST: 'USER_UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'USER_UPDATE_PASSWORD_FAILURE',

  UPDATE_MUST_CHANGE_PASSWORD_SUCCESS: 'USER_UPDATE_MUST_CHANGE_PASSWORD_SUCCESS',

  FIRST_LOGIN_AFFILIATE_REQUEST: 'FIRST_LOGIN_AFFILIATE_REQUEST',
  FIRST_LOGIN_AFFILIATE_SUCCESS: 'FIRST_LOGIN_AFFILIATE_SUCCESS',
  UPDATE_FIRST_LOGIN_SUCCESS: 'UPDATE_FIRST_LOGIN_SUCCESS',
  FIRST_LOGIN_AFFILIATE_FAILURE: 'FIRST_LOGIN_AFFILIATE_FAILURE',

  CREATE_STRIPE_ACCOUNT_REQUEST: 'CREATE_STRIPE_ACCOUNT_REQUEST',
  CREATE_STRIPE_ACCOUNT_SUCCESS: 'CREATE_STRIPE_ACCOUNT_SUCCESS',
  CREATE_STRIPE_ACCOUNT_FAILURE: 'CREATE_STRIPE_ACCOUNT_FAILURE',
};

export default userConstants;
