import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import clsx from 'clsx';
import { orange } from '@mui/material/colors';
import {
  Typography, Box, CircularProgress, Fab,
} from '@mui/material';
import { AccountCircle, Check, Clear } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { userActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '208px',
  },
  buttonSuccess: {
    backgroundColor: orange[500],
    pointerEvents: 'none',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: orange[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

function EmailConfirmationPage({ location }) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const dispatch = useDispatch();
  const confirmed = useSelector((state) => state.registration.confirmed);
  const confirming = useSelector((state) => state.registration.confirming);
  const parsedQueryString = queryString.parse(location.search);
  const intl = useIntl();
  const [queryParams] = useState({
    email: parsedQueryString.email,
    token: parsedQueryString.token,
  });

  const { email, token } = queryParams;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'confirmation.docTitle', defaultMessage: 'Pertix - Confirmación de cuenta' });
    setOpen(true);
    setSuccess(false);
    setLoading(true);

    if (email && token) {
      const successfullyRegisteredUser = intl.formatMessage({ id: 'app.successfullyRegisteredUser', defaultMessage: 'Usuario registrado correctamente' });
      dispatch(userActions.confirm(email, token, successfullyRegisteredUser));
    }
  }, [dispatch, email, token, intl]);

  const handleChangeStatus = () => {
    const currentUser = localStorage.getItem('user');
    if (currentUser && currentUser.email !== email) {
      dispatch(userActions.logout());
    }
    history.push('/login');
  };

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} aria-labelledby="form-dialog-title">
      <DialogContent>
        <Typography color="primary" variant="h4" align="center">
          <FormattedMessage id="confirmation.verifying" defaultMessage="Verificando cuenta de usuario" />
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" height="70%" mt={2}>
          <Typography variant="subtitle2" align="center">
            <FormattedMessage id="confirmation.wait" defaultMessage="Please wait while we verify your account" />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <div className={classes.wrapper}>
            <Fab
              aria-label="save"
              color="primary"
              className={buttonClassname}
              onClick={handleChangeStatus}
            >
              {(() => {
                if (loading && confirming) { return <AccountCircle />; }
                if (confirmed) { return <Check />; }
                return <Clear />;
              })()}
            </Fab>
            {loading && confirming && (
              <CircularProgress
                size={68}
                className={classes.fabProgress}
              />
            )}
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EmailConfirmationPage;
