import axios from '../axios/axios';
import handler from './handler';
import { authHeader } from '../helpers';

function createStripeAccount(affiliateId) {
  const headers = authHeader();
  const getUrl = `Affiliates/${affiliateId}/stripe_account_link`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getStripeAccountStatus(affiliateId) {
  const headers = authHeader();
  const postUrl = `Affiliates/${affiliateId}/refresh_stripe_account_status`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function setFirstLogin(affiliateId) {
  const headers = authHeader();
  const postUrl = `Affiliates/${affiliateId}/first-login`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(affiliateId) {
  const headers = authHeader();
  const getUrl = `Affiliates/${affiliateId}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const affiliatesService = {
  createStripeAccount,
  getStripeAccountStatus,
  get,
  setFirstLogin,
};

export default affiliatesService;
