import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, Box, Grid, Container, Typography,
  InputAdornment, IconButton, Divider,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../controls/Button';
import AuthLayout from '../AuthLayout/AuthLayout';
import GoogleButton from '../../controls/GoogleButton';
import userActions from '../../../redux/actions/user.actions';
import { validationConstants } from '../../../constants';
import { history } from '../../../helpers';

const initialFieldValues = {
  email: '',
  password: '',
  showPassword: false,
};

function AuthPage() {
  const dispatch = useDispatch();
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const [values, setValues] = useState(initialFieldValues);
  const { email, password, showPassword } = values;
  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'auth.docTitle', defaultMessage: 'Pertix - Login' });
    if (history.location.pathname === '/logout') { dispatch(userActions.logout()); }
  }, [dispatch, intl]);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(userActions.login(email, password));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <AuthLayout
      pageTitle={(
        <FormattedMessage
          id="auth.login"
          defaultMessage="Login"
        />
      )}
    >
      <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Container>
              <TextValidator
                value={email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="standard"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                size="small"
                autoFocus
                validators={['required', 'isEmail']}
                errorMessages={[
                  validationConstants.EMAIL_REQUIRED,
                  validationConstants.EMAIL_INVALID,
                ]}
                style={{ fontSize: '12px', fontWeight: 400 }}
              />
              <TextValidator
                value={password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                name="password"
                variant="standard"
                label={<FormattedMessage id="app.password" defaultMessage="Contraseña" />}
                type={showPassword ? 'text' : 'password'}
                id="password"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PASSWORD_REQUIRED]}
                style={{ fontSize: '12px', fontWeight: 400 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex={-1}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>),
                }}
              />

              <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                <Link href="/auth/password/forgot" color="textSecondary" variant="body2" style={{ fontSize: '11px', textDecoration: 'none', color: '#9A9A9A' }}>
                  <FormattedMessage id="app.passwordForgot" defaultMessage="Olvidé mi contraseña" />
                </Link>
              </Box>
            </Container>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="center" mt={4} mb={5}>
          <Button
            style={{ fontSize: '11px', fontWeight: 600, borderRadius: '30px' }}
            type="submit"
            text={<FormattedMessage id="app.login" defaultMessage="Iniciar sesión" />}
            loading={loggingIn}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Divider variant="middle" display="inline" style={{ width: '40%' }} />
          <Typography style={{ fontWeight: 500, fontSize: '14px', color: '#757575' }} display="inline"> O </Typography>
          <Divider variant="middle" display="inline" style={{ width: '40%' }} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <GoogleButton id="app.googleLogin" />
        </Box>

      </ValidatorForm>
    </AuthLayout>
  );
}

export default AuthPage;
