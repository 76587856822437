import { CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { affiliatesActions } from '../../redux/actions';
import AffiliatePage from '../Affiliates/AffiliatePage/AffiliatePage';
import CreateAccountModal from './CreateAccountModal';
import LoggedInLayout from './LoggedInLayout';
import SetNewPasswordModal from './SetNewPasswordModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const HomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const affiliate = useSelector((state) => state.affiliate.data);
  const gettingAffiliate = useSelector((state) => state.affiliate.getting);
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(true);

  useEffect(() => {
    if (user && user.id) {
      dispatch(affiliatesActions.get(user.id));
    }
    document.title = 'Pertix - Affiliates';
  }, [dispatch, user]);

  return (
    <LoggedInLayout>
      {
        affiliate && affiliate.mustChangePassword
        && <SetNewPasswordModal isOpen registration />
      }

      {
        affiliate && !affiliate.mustChangePassword && affiliate.isFirstLogin && (
          <TermsAndConditionsModal
            open={isTermsAndConditionsModalOpen}
            setOpen={setIsTermsAndConditionsModalOpen}
            affiliateId={affiliate.id}
            affiliateCommission={affiliate.commission}
            discountCoupon={affiliate.discountCoupon}
          />
        )
      }

      {
        affiliate && !affiliate.mustChangePassword && !affiliate.isFirstLogin && (
          <CreateAccountModal
            openModal={affiliate && !affiliate.referalSystemEnabled}
            affiliateId={user.id}
          />
        )
      }

      {
        affiliate && !affiliate.mustChangePassword && !affiliate.isFirstLogin
        && affiliate.referalSystemEnabled
        && <AffiliatePage />
      }

      {
        gettingAffiliate && (
          <Box alignItems="center" display="grid" height="-webkit-fill-available" justifyContent="center">
            <CircularProgress />
          </Box>
        )
      }
    </LoggedInLayout>
  );
};

export default HomePage;
