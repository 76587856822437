import { userConstants } from '../../constants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
  case userConstants.LOGIN_REQUEST:
    return {
      loggingIn: true,
      user: action.user,
    };
  case userConstants.GOOGLE_LOGIN_REQUEST:
    return {
      loggingIn: true,
      googleToken: action.token,
    };
  case userConstants.LOGIN_SUCCESS:
  case userConstants.GOOGLE_LOGIN_SUCCESS:
  case userConstants.UPDATE_MUST_CHANGE_PASSWORD_SUCCESS:
    return {
      loggedIn: true,
      user: action.user,
    };
  case userConstants.LOGIN_FAILURE:
  case userConstants.GOOGLE_LOGIN_FAILURE:
  case userConstants.LOGOUT:

    return {};
  default:
    return state;
  }
}
