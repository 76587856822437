import alertActions from './alert.actions';
import { history } from '../../helpers';
import { userConstants } from '../../constants';
import { userService } from '../../services';

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function confirm(email, token, messageSuccess) {
  function request() { return { type: userConstants.REGISTER_CONFIRM_REQUEST }; }
  function success() { return { type: userConstants.REGISTER_CONFIRM_SUCCESS }; }
  function failure() { return { type: userConstants.REGISTER_CONFIRM_FAILURE }; }

  return (dispatch) => {
    dispatch(request({ email }));

    userService.confirm(email, token)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          setTimeout(() => {
            const currentUser = localStorage.getItem('user');
            if (currentUser && currentUser.email !== email) {
              dispatch(logout());
            }
            history.push('/login');
          }, 4000);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function login(username, password) {
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password)
      .then(
        (user) => {
          dispatch(success(user));
          history.push('/home');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function googleLogin(googleToken) {
  function request(token) { return { type: userConstants.GOOGLE_LOGIN_REQUEST, token }; }
  function success(user) { return { type: userConstants.GOOGLE_LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.GOOGLE_LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(googleToken));

    userService.googleLogin(googleToken)
      .then(
        (user) => {
          dispatch(success(user));
          history.push('/home');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function forgotPassword(email) {
  return (dispatch) => {
    userService.forgotPassword(email)
      .then(
        () => {
          dispatch(alertActions.info('Se ha enviado un link de reseteo de contraseña a su casilla'));
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error));
          history.push('/login');
        },
      );
  };
}

function resetPassword(email, newPassword, token) {
  return (dispatch) => {
    userService.resetPassword(email, newPassword, token)
      .then(
        () => {
          dispatch(alertActions.success('Contraseña cambiada correctamente'));
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updatePassword(email, password, newPassword, messageSuccess) {
  function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.UPDATE_PASSWORD_SUCCESS }; }
  function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error }; }
  function clearRegistration() { return { type: userConstants.REGISTER_CONFIRM_CLEAR }; }

  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(email, password, newPassword)
      .then(
        () => {
          dispatch(alertActions.success(messageSuccess));
          dispatch(clearRegistration());
          dispatch(success());
          history.push('/home');
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
}

function updatePasswordAndMustChange(email, password, newPassword, messageSuccess, user) {
  function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.UPDATE_PASSWORD_SUCCESS }; }
  function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error }; }
  function successMustChangePassword() {
    localStorage.setItem('user', JSON.stringify(user));
    return { type: userConstants.UPDATE_MUST_CHANGE_PASSWORD_SUCCESS, user };
  }
  function clearRegistration() { return { type: userConstants.REGISTER_CONFIRM_CLEAR }; }

  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(email, password, newPassword)
      .then(
        () => {
          dispatch(alertActions.success(messageSuccess));
          dispatch(clearRegistration());
          dispatch(successMustChangePassword());
          dispatch(success());
          history.push('/home');
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
}

const userActions = {
  confirm,
  login,
  googleLogin,
  logout,
  forgotPassword,
  resetPassword,
  updatePassword,
  updatePasswordAndMustChange,
};

export default userActions;
