import React, { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import TermsAndConditionsModal from '../Home/TermsAndConditionsModal';

const useStyles = makeStyles(() => ({
  footerWrapper: {
    background: '#757575',
    color: '#FFF',
    width: '100%',
    height: '81px',
    minHeight: '81px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    marginTop: 'auto',
  },
  footerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    maxWidth: '520px',
    width: '100%',
  },
  footerColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const Footer = () => {
  const classes = useStyles();
  const affiliate = useSelector((state) => state.affiliate.data);
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false);

  return (
    <Box className={classes.footerWrapper} pt={1} pb={2}>
      <Box className={classes.footerContainer}>
        <Box className={classes.footerColumn}>
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            <FormattedMessage id="app.product" defaultMessage="PRODUCTO" />
          </Typography>
          <Link href="/" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            <Typography variant="caption">
              <FormattedMessage id="app.plansAndPrice " defaultMessage="Planes y precio" />
            </Typography>
          </Link>
          <Typography variant="caption">
            <a target="_blank" rel="noopener noreferrer" href="mailto:soporte.latam@pertixtech.com" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
              <FormattedMessage id="app.requestSupport " defaultMessage="Pedir soporte" />
            </a>
          </Typography>
        </Box>

        <Box className={classes.footerColumn}>
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            <FormattedMessage id="app.legal " defaultMessage="LEGAL" />
          </Typography>
          <Typography
            variant="caption"
            style={{ color: '#FFFFFF', cursor: 'pointer' }}
            onClick={() => setOpenTermsAndConditionsModal(true)}
          >
            <FormattedMessage id="app.termsAndConditions " defaultMessage="Términos y condiciones" />
          </Typography>
          <Link href="/" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            <Typography variant="caption">
              <FormattedMessage id="app.privacyPolicy " defaultMessage="Política de privacidad" />
            </Typography>
          </Link>
        </Box>

        <Box className={classes.footerColumn}>
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            <FormattedMessage id="app.contacto" defaultMessage="Contacto" />
          </Typography>
          <Link href="mailto:contacto@pertix.com" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            <Typography variant="caption">
              contacto@pertix.com
            </Typography>
          </Link>
          <Typography variant="caption" />
          <Link href="/logout" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            <Typography variant="caption">
              <FormattedMessage id="app.logout" defaultMessage="Cerrar sesión" />
            </Typography>
          </Link>
        </Box>
      </Box>

      <TermsAndConditionsModal
        open={openTermsAndConditionsModal}
        setOpen={setOpenTermsAndConditionsModal}
        affiliateCommission={affiliate && affiliate.commission}
        discountCoupon={affiliate && affiliate.discountCoupon}
      />
    </Box>
  );
};

export default Footer;
