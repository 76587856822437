import React from 'react';
import { Alert, Snackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { alertActions } from '../../redux/actions';

const SnackbarAlert = () => {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const horizontal = 'center';
  const vertical = 'top';

  const handleCloseAlert = () => {
    dispatch(alertActions.clear());
  };

  const alertIconMapping = {
    error: <ErrorIcon fontSize="inherit" />,
    info: <ErrorIcon fontSize="inherit" />,
    success: <CheckCircleIcon fontSize="inherit" />,
    warning: <WarningIcon fontSize="inherit" />,
  };

  return (
    <>
      {alert.message && (
        <Snackbar
          open={Boolean(alert)}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          onClose={handleCloseAlert}
          autoHideDuration={3000}
        >
          <Alert
            onClose={() => {}}
            onClick={handleCloseAlert}
            variant="filled"
            iconMapping={alertIconMapping}
            severity={alert.type}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {alert.message}
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarAlert;
