import axios from '../axios/axios';
import handler from './handler';

function confirm(email, token) {
  const userConfirm = { email, token };
  return axios.post('auth/confirm', userConfirm)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function login(email, password) {
  const user = { email, password };
  return axios.post('auth/login/affiliates', user)
    .then(handler.handleResponse)
    .then((userData) => {
      localStorage.setItem('user', JSON.stringify(userData));
      return userData;
    })
    .catch(handler.handleError);
}

function logout() {
  handler.logout();
}

function googleLogin(token) {
  const googleToken = { token };
  return axios.post('auth/login/google/affiliates', googleToken)
    .then(handler.handleResponse)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    })
    .catch(handler.handleError);
}

function forgotPassword(email) {
  const userForgotPassword = { email };
  return axios.post('affiliates/password/forgot', userForgotPassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function resetPassword(email, newPassword, token) {
  const userResetPassword = { email, newPassword, token };
  return axios.post('auth/password/reset', userResetPassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updatePassword(email, password, newPassword) {
  const userUpdatePassword = { email, password, newPassword };
  return axios.put('affiliates/password', userUpdatePassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const userService = {
  confirm,
  forgotPassword,
  googleLogin,
  login,
  logout,
  resetPassword,
  updatePassword,
};

export default userService;
