import React from 'react';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import Footer from '../controls/Footer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

LoggedInLayout.propTypes = {
  children: PropTypes.element,
};

LoggedInLayout.defaultProps = {
  children: {},
};

export default LoggedInLayout;
