// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import alert from './alert.reducer';
import authentication from './authentication.reducer';
import user from './user.reducer';
import registration from './registration.reducer';
import affiliate from './affiliates.reducer';

const combinedReducer = combineReducers({
  affiliate,
  alert,
  authentication,
  registration,
  user,
});

export default combinedReducer;
