import React from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog, Box, Typography, DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import Button from '../controls/Button';
import { affiliatesText } from '../../constants';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: '468px',
    padding: '24px',
  },
  textDiv: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ReferralSystemActivatedModal() {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 700 }} align="center">
          {affiliatesText.referralSystemActivated}
        </Typography>

        <Box className={classes.textDiv} mt={3}>
          <Typography variant="subtitle2" width="305px">
            {affiliatesText.referralSystemActivatedText}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <Button
            type="submit"
            text={<FormattedMessage id="app.accept" defaultMessage="Aceptar" />}
            style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
            component={Link}
            to="/home"
          />
        </Box>

      </DialogContent>
    </Dialog>
  );
}

export default ReferralSystemActivatedModal;
