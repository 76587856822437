import { errorCodeHelper, history, dispatch } from '../helpers';
import { userActions } from '../redux/actions';

function logout() {
  if (localStorage.getItem('user')) {
    localStorage.removeItem('user');
    history.push('/login');
  }
}

function handleResponse(response) {
  return response.data;
}

function handleError(error) {
  let message = '';
  if (error.response) {
    if (error.response.status === 401) {
      dispatch(userActions.logout());
    }

    if (error.response.status === 500) {
      message = error.response.data.detail;
    } else {
      message = errorCodeHelper.errorTexts(error.response.data.status);
    }
  } else {
    message = errorCodeHelper.errorTexts('default');
  }

  return Promise.reject(message);
}

const handler = {
  logout,
  handleResponse,
  handleError,
};

export default handler;
