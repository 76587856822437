import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { affiliatesActions } from '../../../redux/actions';

const AffiliateRefresh = () => {
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state.affiliate.data);
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (affiliate && !affiliate.referalSystemEnabled) {
      dispatch(affiliatesActions.createStripeAccount(affiliate.id));
    }
    if (!affiliate) {
      dispatch(affiliatesActions.get(user.id));
    }
  }, [affiliate, dispatch, user]);

  if (affiliate && affiliate.referalSystemEnabled) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <>
      Redirecting...
    </>
  );
};

export default AffiliateRefresh;
