/* eslint-disable max-len */
import { userConstants, affiliateConstants } from '../../constants';
import { affiliatesService } from '../../services';
import alertActions from './alert.actions';

function get(affiliateId) {
  function request() { return { type: affiliateConstants.GET_REQUEST }; }
  function success(data) { return { type: affiliateConstants.GET_SUCCESS, data }; }
  function failure() { return { type: affiliateConstants.GET_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliatesService.get(affiliateId)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function setFirstLogin(affiliateId) {
  function request() { return { type: userConstants.FIRST_LOGIN_AFFILIATE_REQUEST }; }
  function success() { return { type: userConstants.FIRST_LOGIN_AFFILIATE_SUCCESS }; }
  function failure(error) { return { type: userConstants.FIRST_LOGIN_AFFILIATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    affiliatesService.setFirstLogin(affiliateId)
      .then(
        () => {
          dispatch(success());
          dispatch(get(affiliateId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getStripeAccountStatus(affiliateId) {
  function request() { return { type: affiliateConstants.GET_STRIPE_ACC_STATUS_REQUEST }; }
  function success(data) { return { type: affiliateConstants.GET_STRIPE_ACC_STATUS_SUCCESS, data }; }
  function failure(error) { return { type: affiliateConstants.GET_STRIPE_ACC_STATUS_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    affiliatesService.getStripeAccountStatus(affiliateId)
      .then(
        (affiliate) => {
          dispatch(success(affiliate));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createStripeAccount(affiliateId) {
  function request() { return { type: userConstants.CREATE_STRIPE_ACCOUNT_REQUEST }; }
  function success() { return { type: userConstants.CREATE_STRIPE_ACCOUNT_SUCCESS }; }
  function failure(error) { return { type: userConstants.CREATE_STRIPE_ACCOUNT_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    affiliatesService.createStripeAccount(affiliateId)
      .then(
        (affiliateUrl) => {
          window.location.href = affiliateUrl;
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const affiliatesActions = {
  createStripeAccount,
  get,
  setFirstLogin,
  getStripeAccountStatus,
};

export default affiliatesActions;
